import { Dialog } from '@gmini/ui-kit/lib/Dialog'
import { DialogTitle } from '@gmini/ui-kit/lib/DialogTitle'

import { RoundedCross } from '@gmini/ui-kit'

import { useState } from 'react'

import * as api from '@gmini/chm-api-sdk'

import { ChecklistForm } from '../ChecklistForm'
import { Scheme } from '../../molecules'

import { Content, CloseButton, Header } from './PreviewChecklistDialog.styled'

type PreviewChecklistDialogProps = {
  templatePopulated: api.Template.TemplatePopulated | null
  onClose: () => void
  open: boolean
}

export const PreviewChecklistDialog = ({
  templatePopulated,
  onClose,
  open,
}: PreviewChecklistDialogProps) => {
  const [selectedSectionIdx, setSelectedSectionIdx] = useState(0)

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const emptyFunction = () => {}

  if (!templatePopulated) {
    return null
  }

  return (
    <Dialog maxWidth='xl' open={open} onClose={onClose}>
      <Header>
        <DialogTitle>Предпросмотр чек-листа</DialogTitle>
        <CloseButton type='square' onClick={onClose}>
          <RoundedCross />
        </CloseButton>
      </Header>

      <Content>
        <Scheme
          sections={templatePopulated.sections}
          title='Схема чек-листа'
          selectedSectionIdx={selectedSectionIdx}
          setSelectedSectionIdx={setSelectedSectionIdx}
          onCreateSection={emptyFunction}
          inInstance
        />
        <ChecklistForm
          selectedSectionIdx={selectedSectionIdx}
          setSelectedSectionIdx={setSelectedSectionIdx}
          onUpdateAnswer={emptyFunction}
          closeStatus={emptyFunction}
          instanceStatus={api.InstanceStatus.Enum.DRAFT}
          editorPending={false}
          template={templatePopulated}
          answers={[]}
          isPreview
        />
      </Content>
    </Dialog>
  )
}
