import { userInfo$ } from '@gmini/common'
import {
  FilterAssignee,
  FilterDate,
  FilterPanel,
  FilterStatus,
  FilterType,
  FilterUser,
} from '@gmini/components'
import { URLSearchParamsCustom } from '@gmini/utils'
import { useStore } from 'effector-react'
import { useEffect } from 'react'

import {
  ASSIGNEE_IDS_IL,
  CREATED_DATE_CODE_IL,
  CREATED_DATE_RANGE_IL,
  TEMPLATE_TYPE_IDS_IL,
  OWNER_IDS_IL,
  PROJECT_IS_NOT_SELECTED_ERROR,
  STATUSES_IL,
  UPDATED_DATE_CODE_IL,
  UPDATED_DATE_RANGE_IL,
} from '../../../constants'

import {
  userList$,
  fetchUserListPending$,
  fetchAllUserListPending$,
} from '../../user.store'

import { templateTypes$, fetchTypesPending$ } from '../../templateTypes.store'

import {
  instanceStatusList$,
  fetchStatusListPending$,
} from '../../instanceStatus'

import { assigneeGroupList$ } from '../../assigneeGroupList'

import {
  allowedFilters$,
  fetchAllowedFilters,
  fetchAllowedFiltersPending$,
} from './model'

type InstanceListFilterPanelProps = {
  onChange: (query: URLSearchParamsCustom) => void
  projectUrn: string | null
}

export const InstanceListFilterPanel = ({
  onChange,
  projectUrn,
}: InstanceListFilterPanelProps) => {
  const allowedFilters = useStore(allowedFilters$)
  const templateTypes = useStore(templateTypes$)
  const userList = useStore(userList$)
  const fetchUserListPending = useStore(fetchUserListPending$)
  const fetchAllUserListPending = useStore(fetchAllUserListPending$)
  const fetchAllowFiltersPending = useStore(fetchAllowedFiltersPending$)
  const fetchTypesPending = useStore(fetchTypesPending$)
  const instanceStatusList = useStore(instanceStatusList$)
  const fetchStatusListPending = useStore(fetchStatusListPending$)
  const userInfo = useStore(userInfo$)
  const assigneeGroupList = useStore(assigneeGroupList$)

  useEffect(() => {
    if (!projectUrn) {
      return
    }

    fetchAllowedFilters({
      projectUrn,
    })
  }, [projectUrn])

  const onChangeHandler = (query: URLSearchParamsCustom) => {
    onChange?.(query)

    if (!projectUrn) {
      throw new Error(PROJECT_IS_NOT_SELECTED_ERROR)
    }

    fetchAllowedFilters({
      projectUrn,
    }) // Чтобы хэндлить изменения чек-листов другими пользователями
  }

  return (
    <FilterPanel
      items={[
        {
          title: 'Дисциплины',
          component: (
            <FilterType
              allowedItems={allowedFilters?.templateTypeIds || []}
              typeList={templateTypes}
              urlKey={TEMPLATE_TYPE_IDS_IL}
              loading={fetchTypesPending}
              disabled={!allowedFilters && fetchAllowFiltersPending}
              countSkeletonItems={10}
              onChange={onChangeHandler}
            />
          ),
        },
        {
          title: 'Статус',
          component: (
            <FilterStatus
              allowedItems={allowedFilters?.statuses || []}
              statusList={instanceStatusList}
              loading={fetchStatusListPending}
              disabled={!allowedFilters && fetchAllowFiltersPending}
              urlKey={STATUSES_IL}
              countSkeletonItems={6}
              onChange={onChangeHandler}
            />
          ),
        },
        {
          title: 'Дата изменения',
          component: (
            <FilterDate
              allowedRange={allowedFilters?.updatedDateRange || null}
              urlKeyCode={UPDATED_DATE_CODE_IL}
              urlKeyValue={UPDATED_DATE_RANGE_IL}
              disabled={!allowedFilters && fetchAllowFiltersPending}
              onChange={onChangeHandler}
            />
          ),
        },
        {
          title: 'Дата создания',
          component: (
            <FilterDate
              allowedRange={allowedFilters?.createdDateRange || null}
              urlKeyCode={CREATED_DATE_CODE_IL}
              urlKeyValue={CREATED_DATE_RANGE_IL}
              onChange={onChangeHandler}
              disabled={!allowedFilters && fetchAllowFiltersPending}
            />
          ),
        },
        {
          title: 'Автор',
          component: (
            <FilterUser
              userList={userList}
              urlKey={OWNER_IDS_IL}
              allowedItems={allowedFilters?.ownerIds || []}
              placeholder='Выбрать автора'
              disabled={
                fetchUserListPending ||
                fetchAllUserListPending ||
                (!allowedFilters && fetchAllowFiltersPending)
              }
              userInfo={userInfo}
              labelFirstEl='Я автор'
              onChange={onChangeHandler}
            />
          ),
        },
        {
          title: 'Ответственный',
          component: (
            <FilterAssignee
              assigneeGroupList={assigneeGroupList.map(group => ({
                ...group,
                options: group.options.map(option => ({
                  ...option,
                  type: option.source,
                })),
              }))}
              urlKeyRole={ASSIGNEE_IDS_IL}
              urlKeyUser={ASSIGNEE_IDS_IL}
              allowedItems={allowedFilters?.assigneeIds || []}
              placeholder='Выбрать ответстве...'
              disabled={
                fetchUserListPending ||
                fetchAllUserListPending ||
                (!allowedFilters && fetchAllowFiltersPending)
              }
              userInfo={userInfo}
              labelFirstEl='Назначено на меня'
              onChange={onChangeHandler}
            />
          ),
        },
      ]}
    />
  )
}
