import { DateFilterItemsCode } from '@gmini/components'
import { URLSearchParamsCustom } from '@gmini/utils'

import {
  ASSIGNEE_IDS_IL,
  CREATED_DATE_CODE_IL,
  CREATED_DATE_RANGE_IL,
  TEMPLATE_TYPE_IDS_IL,
  OWNER_IDS_IL,
  SEARCH_IL,
  STATUSES_IL,
  UPDATED_DATE_CODE_IL,
  UPDATED_DATE_RANGE_IL,
} from '../../../constants'

import { InstanceListFilters } from './InstanceList.types'

export const getInstanceListFilters = (
  query: URLSearchParamsCustom,
): InstanceListFilters => {
  const searchValueFromUrl = query.get(SEARCH_IL) || ''
  const templateTypeIds = query.getArray(TEMPLATE_TYPE_IDS_IL).map(Number)
  const statuses = query.getArray(STATUSES_IL)
  const ownerIds = query.getArray(OWNER_IDS_IL)
  const assigneeIds = query.getArray(ASSIGNEE_IDS_IL)
  const createdDateRange = query.getArray(CREATED_DATE_RANGE_IL)
  const updatedDateRange = query.getArray(UPDATED_DATE_RANGE_IL)
  const createdDateCode = query.get(
    CREATED_DATE_CODE_IL,
  ) as DateFilterItemsCode | null
  const updatedDateCode = query.get(
    UPDATED_DATE_CODE_IL,
  ) as DateFilterItemsCode | null

  return {
    filter: searchValueFromUrl,
    templateTypeIds,
    statuses,
    ownerIds,
    assigneeIds,
    createdDateRange,
    updatedDateRange,
    createdDateCode,
    updatedDateCode,
  }
}
