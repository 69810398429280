import {
  IconButton,
  HorizontalThreeDots,
  PlusPeople,
  Select,
  Trash,
  RadioButton,
} from '@gmini/ui-kit'
import { useState, ChangeEvent } from 'react'

import { DropdownMenu, FieldLabel } from '@gmini/components'

import * as api from '@gmini/chm-api-sdk'

import { deleteQuestion, updateQuestion } from '../../questionCRUD.store'

import {
  FieldContainer,
  SelectRenderValue,
} from '../SectionForm/SectionForm.styled'

import {
  Container,
  LabelCol,
  FieldsCol,
  MenuCol,
  TextArea,
  FormLine,
  AddRole,
  FieldRightSide,
  TextFieldQuestion,
} from './QuestionForm.styled'

type QuestionFormProps = {
  question: api.Template.Question
  idxSection: number
  idxQuestion: number
  selected: boolean | null
  onClick: () => void
  templateId: number
  templateVersion: number
  sectionId: number
  disabled?: boolean
  onToggleEditing: (value: boolean) => void
}

export const QuestionForm = ({
  question,
  idxSection,
  idxQuestion,
  selected,
  onClick,
  templateId,
  templateVersion,
  sectionId,
  disabled,
  onToggleEditing,
}: QuestionFormProps) => {
  const [name, setName] = useState(question.name)
  const [description, setDescription] = useState(question.description)

  const [
    anchorDropdownMenu,
    setAnchorElDropdownMenu,
  ] = useState<HTMLButtonElement | null>(null)

  const handleCloseDropdownMenu = () => {
    setAnchorElDropdownMenu(null)
  }

  const onUpdateField = (fieldName: string, value: string | number) => {
    updateQuestion({
      templateId,
      templateVersion,
      sectionId,
      questionId: question.id,
      question: { ...question, [fieldName]: value },
    })
  }

  const onDelete = () => {
    deleteQuestion({
      questionId: question.id,
      sectionId,
      templateId,
      templateVersion,
    })
  }

  return (
    <Container selected={selected} onClick={onClick}>
      <LabelCol>
        {idxSection}.{idxQuestion + 1}
      </LabelCol>
      <FieldsCol>
        <FormLine>
          <FieldContainer>
            <FieldLabel>Вопрос</FieldLabel>
            <TextFieldQuestion
              placeholder='Введите текст вопроса'
              onChange={(value: string) => {
                setName(value)
              }}
              value={name}
              onBlur={() => {
                if (name !== question.name) {
                  onUpdateField('name', name)
                } else {
                  onToggleEditing(false)
                }
              }}
              disabled={disabled}
              onFocus={() => {
                onToggleEditing(true)
              }}
            />
          </FieldContainer>
          <FieldRightSide>
            <FieldLabel>Вес вопроса</FieldLabel>
            <Select
              options={[]}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onChange={() => {}}
              value={{}}
              renderSelectValue={() => `${question.weight}%`}
              getOptionLabel={() => ''}
              disabled
              placeholder='Выберите вес вопроса'
              emptyOptionListMessage='Нет доступных совпадений'
            />
          </FieldRightSide>
        </FormLine>
        <FormLine>
          <FieldContainer>
            <FieldLabel>Описание</FieldLabel>
            <TextArea
              placeholder='Добавить описание'
              onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                setDescription(event.target.value)
              }
              value={description || ''}
              onBlur={() => {
                if (description && description !== question.description) {
                  onUpdateField('description', description)
                } else {
                  onToggleEditing(false)
                }
              }}
              disabled={disabled}
              onFocus={() => {
                onToggleEditing(true)
              }}
              style={{ background: 'rgba(53,59,96,0.05)' }}
            />
          </FieldContainer>
        </FormLine>
        <FormLine>
          <FieldContainer>
            <FieldLabel>Тип ответа</FieldLabel>
            <Select
              options={[]}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onChange={() => {}}
              value={{}}
              getOptionLabel={() => ''}
              disabled
              renderSelectValue={() => (
                <SelectRenderValue>
                  <RadioButton selected />
                  Да / Нет / Нет ответа
                </SelectRenderValue>
              )}
              emptyOptionListMessage='Нет доступных совпадений'
            />
          </FieldContainer>
        </FormLine>

        <AddRole color='tertiary' leftIcon={<PlusPeople />} disabled>
          Добавить ответственного
        </AddRole>
      </FieldsCol>

      <DropdownMenu
        anchorEl={anchorDropdownMenu}
        handleClose={handleCloseDropdownMenu}
        items={[
          {
            name: 'Удалить',
            onClick: onDelete,
            icon: <Trash color='#353B60' />,
          },
        ]}
      />
      <MenuCol>
        <IconButton
          onClick={event => setAnchorElDropdownMenu(event.currentTarget)}
          size='regular'
          active={Boolean(anchorDropdownMenu)}
          disabled={disabled}
        >
          <HorizontalThreeDots />
        </IconButton>
      </MenuCol>
    </Container>
  )
}
