import { createStore, merge } from 'effector'
import { clone } from 'ramda'

import * as api from '@gmini/chm-api-sdk'

import { actionSectionItemFileEvents } from '../../file.store'

import { fetchTemplate } from '../EditTemplatePage/EditTemplate.store'

export const fetchInstance = api.Instance.fetch.createContext()
export const updateInstance = api.Instance.update.createContext()
export const closeInstanceStatus = api.Instance.closeStatus.createContext()
export const updateAnswer = api.Instance.updateAnswer.createContext()
export const fetchMostRecentInstance = api.Instance.fetchMostRecent.createContext()

export const instanceEvent = merge([
  updateInstance.doneData,
  closeInstanceStatus.doneData,
  updateAnswer.doneData,
  fetchMostRecentInstance.doneData,
])

instanceEvent.watch(({ templateId, templateVersion }) => {
  if (!templateId || !templateVersion) {
    return
  }
  fetchTemplate({ id: templateId, version: templateVersion })
})

export const instanceByIds$ = createStore<
  Record<string, api.Instance.InstancePopulated>
>({})
  .on(instanceEvent, (state, result) => {
    const next = clone(state)

    next[result.id] = result

    return next
  })
  .on(actionSectionItemFileEvents, (state, result) => {
    const next = clone(state)

    const oldInstance = next[result.instanceId]

    next[result.instanceId] = {
      ...oldInstance,
      version: result.instanceVersion,
    }

    return next
  })
