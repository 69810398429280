import {
  Popup,
  Select,
  Magnifier,
  RoundedCross,
  Button,
  Tooltip,
  Avatar,
  EyeInWindow,
  SelectByGroupsMultiple,
  WarningOutlinedRotate,
} from '@gmini/ui-kit'
import { isNotEmpty } from '@gmini/utils'
import { useStore, useStoreMap } from 'effector-react'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { Controller, useForm } from 'react-hook-form'

import {
  FieldError,
  FieldHint,
  FieldHintText,
  HorizontalLine,
} from '@gmini/components'

import * as smApi from '@gmini/sm-api-sdk'

import { Template } from '@gmini/chm-api-sdk'

import { userInfo$ } from '@gmini/common'

import { AssigneeListItem } from '@gmini/helpers'

import { fetchUserList } from '../../user.store'

import { usePreviewChecklistDialog } from '../PreviewChecklistDialog'

import { useTemplatePopulated } from '../../useTemplatePopulated'

import { ZERO_SEARCH } from '../../../constants'

import { assigneeGroupList$ } from '../../assigneeGroupList'

import { fetchRoleList } from '../../role.store'

import {
  fetchTemplateList,
  resetTemplateList,
  templateList$,
  fetchTemplateListPending$,
} from './model'

import {
  Container,
  Header,
  Title,
  Content,
  MessageContent,
  Footer,
  RequiredMessageIndicator,
  CloseButton,
  HelpIcon,
  CircularProgress,
  AssigneeFieldWrapper,
  AssigneeField,
  ShowMore,
  TooltipTitleWrapper,
  TooltipTitleText,
  TooltipContent,
  TextField,
} from './CreateChecklistPopup.styled'
import { FormValue } from './types'

type CreateChecklistPopupProps = {
  onClose?: () => void
  open?: boolean
  onCreateChecklist: (data: FormValue) => void
  projectUrn: string | null
  projectList: smApi.Project[]
  loading?: boolean
  lockProjectSelect?: boolean
}

export const CreateChecklistPopup = ({
  open,
  onClose,
  onCreateChecklist,
  projectUrn,
  projectList,
  loading,
  lockProjectSelect,
}: CreateChecklistPopupProps) => {
  const [offsetTemplateList, setOffsetTemplateList] = useState(0)
  const [offsetAssigneeList, setOffsetAssigneeList] = useState(0)
  const [showFields, setShowFields] = useState(false)
  const [inputTemplateValue, setInputTemplateValue] = useState('')

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    clearErrors,
    setValue,
  } = useForm<FormValue>({ defaultValues, mode: 'onChange' })
  const template = watch('template', null)
  const project = watch('project')
  const name = watch('name')

  const {
    fetchMostRecentTemplate,
    fetchTemplatePopulatedPending,
    templatePopulated,
  } = useTemplatePopulated()

  useEffect(() => {
    if (template) {
      fetchMostRecentTemplate({ id: template.id })
    }
  }, [fetchMostRecentTemplate, template])

  const selectedProject = useMemo(
    () => projectList.find(({ urn }) => urn === projectUrn),
    [projectList, projectUrn],
  )

  useEffect(() => {
    if (selectedProject) {
      setValue('project', selectedProject)
    }
  }, [setValue, selectedProject])

  const fetchTemplateListPending = useStore(fetchTemplateListPending$)
  const assigneeGroupList = useStore(assigneeGroupList$)
  const userInfo = useStore(userInfo$)

  const { templateList, totalTemplates } = useStoreMap({
    store: templateList$,
    keys: [inputTemplateValue],
    fn: ({ byId$, ids$, totalTemplates$ }, [val]) => {
      const search = val || ZERO_SEARCH
      const idsList = ids$[search]

      if (idsList) {
        return {
          templateList: idsList
            .map(id => ({
              ...byId$[id],
            }))
            .filter(isNotEmpty),
          totalTemplates: totalTemplates$,
        }
      }

      return { templateList: [], totalTemplates: null }
    },
  })

  useEffect(() => {
    if (!open) {
      resetTemplateList()
      setOffsetTemplateList(0)
      setOffsetAssigneeList(0)
    }
  }, [open])

  useEffect(() => {
    if (project) {
      fetchTemplateList({
        limit: templateLimit,
        offset: offsetTemplateList,
        filter: inputTemplateValue.toLocaleLowerCase(),
        projectUrn: project.urn,
        initiatorIds: [userInfo?.id].filter(isNotEmpty),
      })
    }
  }, [inputTemplateValue, offsetTemplateList, project, userInfo?.id])

  useEffect(() => {
    if (project) {
      resetTemplateList()
    }
  }, [project])

  useEffect(() => {
    if (project) {
      fetchUserList({
        projectUrn: project?.urn,
      })
      fetchRoleList.submit()
    }
  }, [offsetAssigneeList, project, project?.urn])

  useEffect(() => {
    setShowFields(!!template)
  }, [template])

  const { PreviewChecklistDialog, onOpen } = usePreviewChecklistDialog({
    templatePopulated,
  })

  const onScrollList = useCallback(
    event => {
      const isScrolledToEnd =
        Number(event.target?.scrollTop) + Number(event.target?.clientHeight) >=
        Number(event.target?.scrollHeight) - 20

      if (isScrolledToEnd && templateList?.length < Number(totalTemplates)) {
        setOffsetTemplateList(prevValue => prevValue + templateLimit)
      }
    },
    [templateList?.length, totalTemplates],
  )

  const onSubmit = (data: FormValue) => {
    onCloseHandler()
    onCreateChecklist(data)
  }

  const onCloseHandler = () => {
    onClose?.()
    reset({ ...defaultValues, project: selectedProject })
    clearErrors()
  }

  const tooLargeDescription =
    (template?.description.length || 0) >= descriptionTextLimit

  const templateDescription = (
    <>
      {tooLargeDescription
        ? `${template?.description.slice(0, descriptionTextLimit)}... `
        : template?.description}
      {tooLargeDescription && (
        <Tooltip
          styleContent={{
            display: 'inline-block',
          }}
          style={{ padding: '12px 8px 10px 12px' }}
          title={
            <TooltipContent>
              <TooltipTitleWrapper>
                <WarningOutlinedRotate color='rgba(255, 255, 255, 0.25)' />
                <TooltipTitleText>Описание шаблона</TooltipTitleText>
              </TooltipTitleWrapper>
              {template?.description}
            </TooltipContent>
          }
        >
          <ShowMore>Показать всё</ShowMore>
        </Tooltip>
      )}
    </>
  )

  const disabledCreateButton = loading || !template || !name || !project
  const assigneeHintVisible = !template || template.description

  return (
    <Popup open={Boolean(open)} onClose={onCloseHandler} width='600px'>
      {PreviewChecklistDialog}
      <Container>
        <Header>
          <Title>Создать чек-лист</Title>

          {onClose && (
            <CloseButton onClick={onCloseHandler}>
              <RoundedCross />
            </CloseButton>
          )}
        </Header>
        <Content>
          <div>
            <MessageContent>
              Проект
              <RequiredMessageIndicator>*</RequiredMessageIndicator>
            </MessageContent>
            <div>
              <Controller
                name='project'
                control={control}
                rules={{
                  required: { value: true, message: requiredErrorMessage },
                  maxLength: { value: 256, message: maxLengthErrorMessage },
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    onChange={value => {
                      reset({ ...defaultValues, project: value })
                      field.onChange(value)
                    }}
                    placeholder='Выберите проект'
                    error={Boolean(errors.project)}
                    options={projectList}
                    getOptionLabel={(option: smApi.Project) => option.name}
                    emptyOptionListMessage='Нет доступных совпадений'
                    lockSelect={lockProjectSelect}
                    allowDelete={false}
                  />
                )}
              />
              <FieldError hidden={!errors.project}>
                {errors.project && 'message' in errors.project
                  ? errors.project.message
                  : null}
              </FieldError>
            </div>
          </div>
          <div>
            <MessageContent>
              Выберите шаблон на основе которого будет создан чек-лист
              <RequiredMessageIndicator>*</RequiredMessageIndicator>
              <Tooltip
                noMaxWidth
                title='Создать новый чек-лист можно только на основе шаблона.'
              >
                <HelpIcon color='rgba(53, 59, 96, 0.25)' />
              </Tooltip>
            </MessageContent>

            <div>
              <Controller
                name='template'
                control={control}
                rules={{
                  required: { value: true, message: requiredErrorMessage },
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    onChange={value => {
                      reset({
                        ...defaultValues,
                        template: value,
                        project,
                      })
                      field.onChange(value)
                    }}
                    onInputChange={value => {
                      if (inputTemplateValue.length === 0 && value.length > 0) {
                        setOffsetTemplateList(0)
                      }
                      setInputTemplateValue(value)
                    }}
                    getOptionLabel={(option: Template.Template) => option.name}
                    options={templateList}
                    emptyOptionListMessage='Нет доступных совпадений'
                    loading={fetchTemplateListPending}
                    placeholder='Выберите шаблон'
                    icon={<Magnifier />}
                    onScrollList={onScrollList}
                    disabled={!project}
                  />
                )}
              />
              {assigneeHintVisible && (
                <FieldHint>
                  <WarningOutlinedRotate color='rgba(53, 59, 96, 0.25)' />
                  <FieldHintText transparent={!template}>
                    {!template
                      ? 'В списке отображаются только те шаблоны, где вы назначены инициатором'
                      : templateDescription}
                  </FieldHintText>
                </FieldHint>
              )}
            </div>
          </div>

          {showFields && (
            <>
              {assigneeHintVisible && <HorizontalLine mt='-2px' />}
              <div>
                <MessageContent>
                  Имя чек-листа
                  <RequiredMessageIndicator>*</RequiredMessageIndicator>
                </MessageContent>
                <div>
                  <Controller
                    name='name'
                    control={control}
                    rules={{
                      required: { value: true, message: requiredErrorMessage },
                      maxLength: { value: 256, message: maxLengthErrorMessage },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder='Задайте имя чек-листа'
                        background='#F4F4F8'
                        error={Boolean(errors.name)}
                        disabled={loading || !project}
                      />
                    )}
                  />
                  <FieldError hidden={!errors.name}>
                    {errors.name && errors.name?.message
                      ? errors.name?.message
                      : null}
                  </FieldError>
                </div>
              </div>
              <div>
                <AssigneeFieldWrapper>
                  <AssigneeField>
                    <MessageContent>
                      Ответственный по чек-листу
                      <RequiredMessageIndicator></RequiredMessageIndicator>
                    </MessageContent>

                    <Controller
                      name='assignees'
                      control={control}
                      render={({ field }) => (
                        <SelectByGroupsMultiple
                          {...field}
                          getOptionLabel={(option: AssigneeListItem) =>
                            option.label
                          }
                          groups={assigneeGroupList}
                          emptyOptionListMessage='Нет доступных совпадений'
                          loading={fetchTemplateListPending}
                          placeholder='Выберите ответственного'
                          icon={<Avatar width='24px' height='24px' />}
                          error={Boolean(errors.assignees)}
                          disabled={!project}
                        />
                      )}
                    />

                    <FieldError hidden={!errors.assignees}>
                      {errors.assignees && 'message' in errors.assignees
                        ? (errors.assignees as { message: string }).message //TODO убрать каст
                        : null}
                    </FieldError>
                  </AssigneeField>
                </AssigneeFieldWrapper>
              </div>
            </>
          )}
        </Content>

        <Footer>
          {showFields && (
            <Button
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onClick={onOpen}
              style={{ marginRight: 'auto' }}
              leftIcon={<EyeInWindow />}
              color='tertiary'
              disabled={
                fetchTemplatePopulatedPending ||
                !project ||
                loading ||
                !template
              }
            >
              Предпросмотр чек-листа
              {(fetchTemplatePopulatedPending || loading) && (
                <CircularProgress size={18} />
              )}
            </Button>
          )}
          {onClose && (
            <Button size='regular' color='secondary' onClick={onCloseHandler}>
              Отменить
            </Button>
          )}

          <Button
            size='regular'
            disabled={disabledCreateButton}
            onClick={handleSubmit(onSubmit)}
          >
            Создать
          </Button>
        </Footer>
      </Container>
    </Popup>
  )
}

const templateLimit = 20

const defaultValues = {
  name: '',
  template: null,
  assignee: null,
  project: null,
  assignees: [],
}

const descriptionTextLimit = 220

const requiredErrorMessage = 'Это поле является обязательным'
const maxLengthErrorMessage =
  'Максимальная допустимая длина имени - 256 символов'
