import { useCallback, useEffect, useState } from 'react'

import {
  IconButton,
  UnfoldLess,
  Select,
  Setting,
  ToggleSwitch,
  UnfoldMore,
  TextArea,
  SelectByGroupsMultiple,
  WarningOutlinedRotate,
} from '@gmini/ui-kit'

import { useStore } from 'effector-react'

import {
  TextFieldWhite,
  Scrollbars,
  FieldHint,
  FieldHintText,
} from '@gmini/components'

import * as api from '@gmini/chm-api-sdk'

import {
  AssigneeListItem,
  checkChangesArray,
  useAssignees,
} from '@gmini/helpers'

import {
  AutoCreateLabel,
  IssuesLabel,
  SettingTemplateContainer,
  SettingTemplateItem,
  SettingTemplateWrapper,
  Subtitle,
  SwitcherSection,
  SwitcherSectionRow,
} from '../../pages/CreateTemplatePage/CreateTemplatePage.styled'

import { templateTypes$ } from '../../templateTypes.store'

import { DetailsSummary } from '../../molecules'

import {
  fetchMostRecentTemplate,
  updateTemplate,
} from '../../pages/EditTemplatePage/EditTemplate.store'

import {
  assigneeGroupList$,
  assigneeRoleList$,
  assigneeAllUserList$,
  assigneeCompanyList$,
} from '../../assigneeGroupList'

import {
  DetailsContainer,
  DetailsHeader,
  DetailsFields,
  DetailsField,
  DetailsFieldLabel,
  DetailsTitle,
  DetailsWrapper,
} from './TemplateDetailsPanel.styled'

type TemplateDetailsPanelProps = {
  template: api.Template.TemplatePopulated
  id: number
  version: number
  disabled?: boolean
  onToggleEditing: (value: boolean) => void
  editing: boolean
  inPreview?: boolean
}

export const TemplateDetailsPanel = ({
  template,
  version,
  id,
  disabled,
  onToggleEditing,
  editing,
  inPreview,
}: TemplateDetailsPanelProps) => {
  const [collapsedPanel, setCollapsedPanel] = useState(false)
  const questionCount =
    template.sections.reduce(
      (acc: number, el) => (el.items ? acc + el.items.length : acc),
      0,
    ) || 0
  const countSection = template.sections.length || 0
  const [nameTemplate, setNameTemplate] = useState(template.name)
  const [description, setDescription] = useState(template.description)
  const [allowAssign, setAllowAssign] = useState(template.allowSectionAssignee)
  const [delayedToggleAllowAssign, setDelayedToggleAllowAssign] = useState(
    false,
  )

  const assigneeGroupList = useStore(assigneeGroupList$)
  const getInitiators = useAssignees({
    assigneeRoleList$,
    assigneeUserList$: assigneeAllUserList$,
    assigneeCompanyList$,
  })
  const initiatorsInit = getInitiators(template.initiators)

  const [initiators, setInitiators] = useState(initiatorsInit)

  const templateTypes = useStore(templateTypes$)

  useEffect(() => {
    setNameTemplate(template.name)
  }, [template.allowSectionAssignee, template.name])

  const onUpdateTemplate = useCallback(
    (params: Partial<api.Template.UpdateTemplateParams>) => {
      updateTemplate({ ...template, id, version, ...params })
    },
    [id, template, version],
  )

  useEffect(() => {
    const toggleAllowAssignSubscription = fetchMostRecentTemplate.doneData.watch(
      ({ version }) => {
        if (delayedToggleAllowAssign) {
          updateTemplate({
            ...template,
            name: nameTemplate,
            id,
            version,
            allowSectionAssignee: allowAssign,
          })
          setDelayedToggleAllowAssign(false)
        }
      },
    )

    return () => {
      toggleAllowAssignSubscription.unsubscribe()
    }
  }, [allowAssign, delayedToggleAllowAssign, id, nameTemplate, template])

  const onToggleAllowSectionAssignee = (value: boolean) => {
    setAllowAssign(value)
    if (editing) {
      setDelayedToggleAllowAssign(true)
      return
    }
    onUpdateTemplate({ allowSectionAssignee: value })
  }

  const onChangeDescription = useCallback(
    (value: string) => {
      if (value.length > 300) {
        return
      }
      onUpdateTemplate({ description: value })
    },
    [onUpdateTemplate],
  )

  const onChangeTemplateType = useCallback(
    value => {
      onUpdateTemplate({ templateTypeId: value?.id || null })
    },
    [onUpdateTemplate],
  )

  const onChangeTemplateInitiators = useCallback(
    (value: AssigneeListItem[]) => {
      const existChanges = checkChangesArray(initiatorsInit, value)
      if (!existChanges) {
        return
      }

      onUpdateTemplate({
        initiators: value.map(({ id, source }) => ({
          initiatorId: id,
          source,
        })),
      })
    },
    [initiatorsInit, onUpdateTemplate],
  )

  return (
    <DetailsWrapper collapsed={collapsedPanel}>
      <Scrollbars>
        <DetailsContainer collapsed={collapsedPanel}>
          <DetailsHeader>
            <DetailsTitle collapsed={collapsedPanel}>
              Детали шаблона
            </DetailsTitle>
            <IconButton
              size='regular'
              onClick={() => setCollapsedPanel(prev => !prev)}
            >
              {collapsedPanel ? <UnfoldMore /> : <UnfoldLess />}
            </IconButton>
          </DetailsHeader>
          {!collapsedPanel && (
            <>
              <DetailsSummary
                countSection={countSection}
                questionCount={questionCount}
                signCount={0}
              />
              <DetailsFields>
                <DetailsField>
                  <DetailsFieldLabel>Название шаблона</DetailsFieldLabel>
                  <TextFieldWhite
                    placeholder='Задайте название'
                    onChange={(value: string) => setNameTemplate(value)}
                    value={nameTemplate}
                    error={!nameTemplate.trim()}
                    onBlur={() => {
                      if (
                        nameTemplate !== template.name &&
                        nameTemplate.trim()
                      ) {
                        onUpdateTemplate({ name: nameTemplate })
                      } else {
                        onToggleEditing(false)
                      }
                    }}
                    disabled={disabled}
                    onFocus={() => {
                      onToggleEditing(true)
                    }}
                    inputStyles={{ background: '#fff' }}
                  />
                </DetailsField>
                <DetailsField>
                  <DetailsFieldLabel>Описание</DetailsFieldLabel>
                  <TextArea
                    placeholder='Задайте описание'
                    onChange={e => setDescription(e.currentTarget.value)}
                    value={description}
                    error={description.trim().length > 300}
                    onBlur={() => {
                      if (
                        description !== template.description &&
                        description.trim()
                      ) {
                        onChangeDescription(description)
                      } else {
                        onToggleEditing(false)
                      }
                    }}
                    disabled={disabled}
                    onFocus={() => {
                      onToggleEditing(true)
                    }}
                  />
                </DetailsField>
                <DetailsField>
                  <DetailsFieldLabel>Дисциплина шаблона</DetailsFieldLabel>
                  <Select
                    onChange={onChangeTemplateType}
                    value={
                      templateTypes.find(
                        ({ id }) => id === template.templateTypeId,
                      ) || null
                    }
                    placeholder='Выберите дисциплину шаблона'
                    options={templateTypes}
                    getOptionLabel={(option: api.Template.TemplateType) =>
                      option.name
                    }
                    colorScheme='white'
                    disabled={disabled}
                    emptyOptionListMessage='Нет доступных совпадений'
                  />
                </DetailsField>
                <DetailsField>
                  <DetailsFieldLabel>Инициатор чек-листа</DetailsFieldLabel>

                  <SelectByGroupsMultiple
                    onChange={setInitiators}
                    onBlur={onChangeTemplateInitiators}
                    onReset={() => {
                      setInitiators([])
                      onChangeTemplateInitiators([])
                    }}
                    value={initiators}
                    placeholder='Выберите инициатора'
                    groups={assigneeGroupList}
                    getOptionLabel={(option: AssigneeListItem) => option.label}
                    colorScheme='white'
                    disabled={disabled}
                    emptyOptionListMessage='Нет доступных совпадений'
                  />
                  {!initiators.length && (
                    <FieldHint>
                      <WarningOutlinedRotate color='rgba(53, 59, 96, 0.25)' />
                      <FieldHintText transparent>
                        Только выбранные инициаторы смогут создать <br />
                        чеклист на основе этого шаблона.
                      </FieldHintText>
                    </FieldHint>
                  )}
                </DetailsField>
              </DetailsFields>

              <SwitcherSection>
                <SwitcherSectionRow>
                  <SettingTemplateContainer>
                    <Subtitle>Настройка замечаний</Subtitle>
                    <SettingTemplateWrapper>
                      <SettingTemplateItem>
                        <IssuesLabel>Замечания не определены</IssuesLabel>
                        <IconButton disabled>
                          <Setting />
                        </IconButton>
                      </SettingTemplateItem>
                      <SettingTemplateItem>
                        <AutoCreateLabel>Автосоздание</AutoCreateLabel>
                        <ToggleSwitch
                          size='medium'
                          color='#4C5ECF'
                          hoverColor='#4A60ED'
                          selectedColor='#2235AA'
                          disabled
                        />
                      </SettingTemplateItem>
                    </SettingTemplateWrapper>
                  </SettingTemplateContainer>
                </SwitcherSectionRow>

                <SwitcherSectionRow>
                  <Subtitle>
                    Разрешить добавление ответственных на секции
                  </Subtitle>

                  <ToggleSwitch
                    color='#4C5ECF'
                    hoverColor='#4A60ED'
                    selectedColor='#2235AA'
                    size='medium'
                    active={allowAssign}
                    onToggle={onToggleAllowSectionAssignee}
                    disabled={inPreview}
                  />
                </SwitcherSectionRow>
                <SwitcherSectionRow>
                  <Subtitle>
                    Требовать обязательные подписи в чек-листе
                  </Subtitle>
                  <ToggleSwitch
                    color='#4C5ECF'
                    hoverColor='#4A60ED'
                    selectedColor='#2235AA'
                    size='medium'
                    disabled
                  />
                </SwitcherSectionRow>
              </SwitcherSection>
            </>
          )}
        </DetailsContainer>
      </Scrollbars>
    </DetailsWrapper>
  )
}
