import { createProjectListService } from '@gmini/helpers'
import * as smApi from '@gmini/sm-api-sdk'

import * as api from '@gmini/chm-api-sdk'

import { createTemplateListService } from '../../createTemplateListService'

export const {
  fetchProjectList,
  fetchProjectListPending$,
  projectList$,
} = createProjectListService({
  fetchProjectListApi: smApi.Project.fetchList,
})

export const {
  fetchTemplateList,
  resetTemplateList,
  templateList$,
  fetchTemplateListPending$,
} = createTemplateListService({
  createTemplateApi: api.Template.createTemplate,
  deleteTemplateApi: api.Template.deleteTemplate,
  fetchTemplateListApi: api.Template.fetchList,
})
