import { Button, IconButton, Pointer } from '@gmini/ui-kit'
import { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'

import { useStore, useStoreMap } from 'effector-react'

import { useQuery } from '@gmini/utils'

import {
  SwitchProjectBadgeWrapper,
  TextFieldWithPreview,
} from '@gmini/components'

import { ProjectBadge } from '@gmini/common'

import * as api from '@gmini/chm-api-sdk'

import { Scheme, InstanceStatusRender } from '../../molecules'

import {
  Container,
  Footer,
  Content,
} from '../EditTemplatePage/EditTemplatePage.styled'

import { templateByIds$ } from '../EditTemplatePage/EditTemplate.store'

import {
  ChecklistManagerWrapper,
  InstanceDetailsPanel,
  ChecklistForm,
} from '../../organisms'

import { editorPending$ } from '../../editorPending'

import {
  HeaderLeftPanelEditPage,
  LeftPanelEditPage,
  ContentLeftPanelEditPage,
} from '../../atoms'

import {
  fetchProjectList,
  fetchProjectListPending$,
  projectList$,
} from './model'

import {
  fetchMostRecentInstance,
  instanceByIds$,
  updateInstance,
  closeInstanceStatus,
  updateAnswer,
} from './EditInstance.store'
import { InstanceStatusWrap } from './EditInstancePage.styled'

export const EditInstancePage = () => {
  const history = useHistory()
  const params = useParams<{ id: string }>()
  const id = Number(params.id)

  const query = useQuery()

  const instance = useStoreMap({
    store: instanceByIds$,
    keys: [id],
    fn: (instanceByIds, [id]) =>
      instanceByIds && id && id in instanceByIds ? instanceByIds[id] : null,
  })

  const template = useStoreMap({
    store: templateByIds$,
    keys: [instance?.templateId],
    fn: (templatesByIds, [id]) =>
      templatesByIds && id && id in templatesByIds ? templatesByIds[id] : null,
  })

  const editorPending = useStore(editorPending$)
  const projectList = useStore(projectList$)

  const [selectedSectionIdx, setSelectedSectionIdx] = useState(0)
  const fetchProjectListPending = useStore(fetchProjectListPending$)

  useEffect(() => {
    if (!id) {
      return
    }

    fetchMostRecentInstance({ id })
  }, [id])

  useEffect(() => {
    fetchProjectList.submit()
  }, [])

  const selectedProject = useMemo(
    () => projectList.find(({ urn }) => urn === instance?.projectUrn) || null,
    [instance?.projectUrn, projectList],
  )

  if (!id || !instance) {
    return null
  }

  const onChangeNameTemplate = (value: string) => {
    updateInstance({
      id,
      version: instance.version,
      name: value,
    })
  }

  const onUpdateAnswer = (props: api.Instance.InstanceItemProperty) => {
    updateAnswer({
      id,
      version: instance.version,
      answer: props.answer,
      templateSectionItemId: props.templateSectionItemId,
    })
  }

  const closeStatus = () => {
    closeInstanceStatus({ id, version: instance.version })
  }

  const goBack = () => {
    history.push({ pathname: '/', search: query.toString() })
  }

  return (
    <ChecklistManagerWrapper
      brandSecondary={
        <SwitchProjectBadgeWrapper>
          <ProjectBadge
            projectName={selectedProject?.name || ''}
            loading={fetchProjectListPending}
          />
        </SwitchProjectBadgeWrapper>
      }
    >
      <Container>
        <Content>
          <LeftPanelEditPage>
            <HeaderLeftPanelEditPage>
              <IconButton onClick={goBack}>
                <Pointer color='rgba(53, 59, 96, 0.5)' />
              </IconButton>
              <InstanceStatusWrap>
                <InstanceStatusRender status={instance.status} />
              </InstanceStatusWrap>
              <TextFieldWithPreview
                value={instance.name}
                onChange={onChangeNameTemplate}
                disabled={editorPending}
              />
            </HeaderLeftPanelEditPage>
            <ContentLeftPanelEditPage>
              {template ? (
                <Scheme
                  sections={template.sections}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onCreateSection={() => {}}
                  title='Схема чек-листа'
                  selectedSectionIdx={selectedSectionIdx}
                  setSelectedSectionIdx={setSelectedSectionIdx}
                  inInstance
                />
              ) : null}
              {template ? (
                <ChecklistForm
                  selectedSectionIdx={selectedSectionIdx}
                  setSelectedSectionIdx={setSelectedSectionIdx}
                  template={template}
                  onUpdateAnswer={onUpdateAnswer}
                  closeStatus={closeStatus}
                  answers={instance.itemProperties}
                  editorPending={editorPending}
                  instanceStatus={instance.status}
                  instanceVersion={instance.version}
                />
              ) : null}
            </ContentLeftPanelEditPage>
          </LeftPanelEditPage>
          {template ? (
            <InstanceDetailsPanel
              template={template}
              instance={instance}
              disabled={editorPending}
            />
          ) : null}
        </Content>
        <Footer>
          <Button size='regular' color='secondary' onClick={goBack}>
            Выйти
          </Button>
        </Footer>
      </Container>
    </ChecklistManagerWrapper>
  )
}
