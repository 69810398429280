import { createStore, merge } from 'effector'
import { clone } from 'ramda'

import * as api from '@gmini/chm-api-sdk'

import {
  createQuestion,
  deleteQuestion,
  updateQuestion,
} from '../../questionCRUD.store'

import {
  createSection,
  updateSection,
  deleteSection,
} from '../../sectionCRUD.store'

export const fetchTemplate = api.Template.fetchTemplate.createContext()
export const updateTemplate = api.Template.updateTemplate.createContext()
export const fetchMostRecentTemplate = api.Template.fetchTemplateMostRecent.createContext()

merge([updateTemplate.doneData]).watch(({ id }) => {
  fetchMostRecentTemplate({ id })
})

merge([
  createQuestion.doneData,
  deleteQuestion.doneData,
  updateQuestion.doneData,
  createSection.doneData,
  updateSection.doneData,
  deleteSection.doneData,
]).watch(({ templateId }) => {
  fetchMostRecentTemplate({ id: templateId })
})

export const templateByIds$ = createStore<
  Record<string, api.Template.TemplatePopulated>
>({}).on(
  merge([fetchMostRecentTemplate.doneData, fetchTemplate.doneData]),
  (state, result) => {
    const next = clone(state)

    next[result.id] = result

    return next
  },
)
