export const wsUrl = getEnv('SPA_WS_URL')
export const sentryUrl = getEnv('SPA_FIELD_INSPECTION_SENTRY_DSN')
export const envType = getEnv('SPA_ENV_TYPE')

export const amplitudeApiKey = getEnv('SPA_AMPLITUDE_API_KEY')
export const includeDevFeatures = getEnv('SPA_INCLUDE_DEV_FEATURES') === 'true'
export const envLinks = getEnv('SPA_LINKS_CONFIG')
export const localBackUrl = process.env.LOCAL_BACK_URL

export const MAX_NESTING_LEVEL = 6

export const DEFAULT_FETCH_LIMIT = 40

const {
  SPA_LOG_EFFECTOR,
}: {
  readonly SPA_LOG_EFFECTOR: undefined | string
} = globalThis as any

export const logEffector = !!SPA_LOG_EFFECTOR && SPA_LOG_EFFECTOR !== 'false'

export function getEnv(name: string): string {
  const value: null | string = (globalThis as any)[name] || null

  if (value == null) {
    return ''
  }

  return value
}
