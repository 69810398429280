import { equals } from 'ramda'

export const checkChangesArray = <T extends { id: string }>(
  prevValue: T[],
  newValue: T[],
) => {
  const compareFunction = (a: T, b: T) => {
    const idA = a.id.toUpperCase()
    const idB = b.id.toUpperCase()
    if (idA < idB) {
      return -1
    }
    if (idA > idB) {
      return 1
    }

    return 0
  }

  const sortedPrevValue = [...prevValue].sort(compareFunction)
  const sortedNewValue = [...newValue].sort(compareFunction)

  return !equals(sortedPrevValue, sortedNewValue)
}
