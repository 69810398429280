import { DateFilterItemsCode } from '@gmini/components'
import { URLSearchParamsCustom } from '@gmini/utils'

import {
  SEARCH_TL,
  TEMPLATE_TYPE_IDS_TL,
  OWNER_IDS_TL,
  CREATED_DATE_RANGE_TL,
  UPDATED_DATE_RANGE_TL,
  CREATED_DATE_CODE_TL,
  UPDATED_DATE_CODE_TL,
  INITIATORS_IDS_TL,
} from '../../../constants'

import { TemplateListFilters } from './TemplateList.types'

export const getTemplateListFilters = (
  query: URLSearchParamsCustom,
): TemplateListFilters => {
  const searchValueFromUrl = query.get(SEARCH_TL) || ''
  const templateTypeIds = query.getArray(TEMPLATE_TYPE_IDS_TL).map(Number)
  const ownerIds = query.getArray(OWNER_IDS_TL)
  const createdDateRange = query.getArray(CREATED_DATE_RANGE_TL)
  const updatedDateRange = query.getArray(UPDATED_DATE_RANGE_TL)
  const createdDateCode = query.get(
    CREATED_DATE_CODE_TL,
  ) as DateFilterItemsCode | null
  const updatedDateCode = query.get(
    UPDATED_DATE_CODE_TL,
  ) as DateFilterItemsCode | null
  const initiatorIds = query.getArray(INITIATORS_IDS_TL)

  return {
    filter: searchValueFromUrl,
    templateTypeIds,
    ownerIds,
    createdDateRange,
    updatedDateRange,
    createdDateCode,
    updatedDateCode,
    initiatorIds,
  }
}
