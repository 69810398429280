import { AppliedFilters } from '@gmini/components'
import {
  useAppliedFilterDateData,
  useAppliedFilterTypeData,
  useAppliedFilterStatusData,
  useAppliedFilterUserData,
} from '@gmini/helpers'
import { URLSearchParamsCustom } from '@gmini/utils'
import { useStore } from 'effector-react'
import { useMemo } from 'react'

import {
  ASSIGNEE_IDS_IL,
  CREATED_DATE_RANGE_IL,
  TEMPLATE_TYPE_IDS_IL,
  OWNER_IDS_IL,
  STATUSES_IL,
  UPDATED_DATE_RANGE_IL,
  SHORT_DISPLAY_DATE_FORMAT,
  UPDATED_DATE_CODE_IL,
  CREATED_DATE_CODE_IL,
} from '../../../constants'

import { templateTypes$ } from '../../templateTypes.store'
import { userList$ } from '../../user.store'

import { instanceStatusList$ } from '../../instanceStatus'
import { roleList$ } from '../../role.store'

type InstanceListAppliedFiltersProps = {
  onChange?: (query: URLSearchParamsCustom) => void
}

export const InstanceListAppliedFilters = ({
  onChange,
}: InstanceListAppliedFiltersProps) => {
  const templateTypes = useStore(templateTypes$)
  const userList = useStore(userList$)
  const instanceStatusList = useStore(instanceStatusList$)
  const roleList = useStore(roleList$)

  const preparedTemplateTypes = useAppliedFilterTypeData({
    name: 'Дисциплина',
    urlKey: TEMPLATE_TYPE_IDS_IL,
    typeList: templateTypes,
  })

  const preparedInstanceStatuses = useAppliedFilterStatusData({
    name: 'Статус',
    urlKey: STATUSES_IL,
    statusList: instanceStatusList,
  })

  const preparedOwners = useAppliedFilterUserData({
    name: 'Автор',
    urlKey: OWNER_IDS_IL,
    userList,
  })

  const preparedAssignee = useAppliedFilterUserData({
    name: 'Назначено на',
    urlKey: [ASSIGNEE_IDS_IL],
    userList: [
      ...userList,
      ...roleList.map(role => ({ ...role, name: role.title })),
    ],
  })

  const preparedUpdatedDate = useAppliedFilterDateData({
    dateFormat: SHORT_DISPLAY_DATE_FORMAT,
    urlKeyCode: UPDATED_DATE_CODE_IL,
    urlKeyValue: UPDATED_DATE_RANGE_IL,
    name: 'Изменен',
  })

  const preparedCreatedDate = useAppliedFilterDateData({
    dateFormat: SHORT_DISPLAY_DATE_FORMAT,
    urlKeyCode: CREATED_DATE_CODE_IL,
    urlKeyValue: CREATED_DATE_RANGE_IL,
    name: 'Создан',
  })

  const appliedFilters = useMemo(
    () =>
      [
        preparedTemplateTypes,
        preparedInstanceStatuses,
        preparedUpdatedDate,
        preparedCreatedDate,
        preparedOwners,
        preparedAssignee,
      ].filter(({ value }) => value.length),
    [
      preparedTemplateTypes,
      preparedInstanceStatuses,
      preparedUpdatedDate,
      preparedCreatedDate,
      preparedOwners,
      preparedAssignee,
    ],
  )

  return <AppliedFilters onChange={onChange} items={appliedFilters} />
}
