import * as api from '@gmini/chm-api-sdk'

export const DEFAULT_DISPLAY_DATE_FORMAT = 'DD.MM.YYYY'
export const FULL_DISPLAY_DATE_FORMAT = 'D MMM YYYY HH:mm'
export const SHORT_DISPLAY_DATE_FORMAT = 'MMM D YYYY'
export const LOCAL_ORIGIN = 'http://127.0.0.1:3000'
export const DEVELOPMENT_MODE = process.env.NODE_ENV === 'development'
export const PRODUCTION_MODE = process.env.NODE_ENV === 'production'
export const ZERO_SEARCH = 'áÝé¾±µ'
export const PROJECT_IS_NOT_SELECTED_ERROR = 'Project is not selected'

export enum TabIndex {
  InstanceList,
  TemplateList,
}

export const instanceStatusColorMap: Record<api.InstanceStatus.Enum, string> = {
  IN_PROCESS: '#F5A623',
  FINISHED: '#0D9966',
  DRAFT: '#A7A9AC',
}

export const defaultInstanceStatusColor = '#A7A9AC'

//shared keys query params
export const SELECTED_TAB = 'selectedTab'
export const PROJECT_URN = 'projectUrn'

// instance list keys query params
export const TEMPLATE_TYPE_IDS_IL = 'templateTypeIdsIL'
export const OWNER_IDS_IL = 'ownerIdsIL'
export const ASSIGNEE_IDS_IL = 'assigneeIdsIL'
export const STATUSES_IL = 'statusesIL'
export const UPDATED_DATE_RANGE_IL = 'updatedDateRangeIL'
export const UPDATED_DATE_CODE_IL = 'updatedDateCodeIL'
export const CREATED_DATE_RANGE_IL = 'createdDateRangeIL'
export const CREATED_DATE_CODE_IL = 'createdDateCodeIL'
export const SEARCH_IL = 'searchIL'
export const INSTANCE_ID = 'instanceId'

// template list keys query params
export const TEMPLATE_TYPE_IDS_TL = 'templateTypeIdsTL'
export const OWNER_IDS_TL = 'ownerIdsTL'
export const STATUSES_TL = 'statusesTL'
export const UPDATED_DATE_RANGE_TL = 'updatedDateRangeTL'
export const UPDATED_DATE_CODE_TL = 'updatedDateCodeIT'
export const CREATED_DATE_RANGE_TL = 'createdDateRangeTL'
export const CREATED_DATE_CODE_TL = 'createdDateCodeTL'
export const SEARCH_TL = 'searchTL'
export const TEMPLATE_ID = 'templateId'
export const INITIATORS_IDS_TL = 'initiatorsIdsTL'
