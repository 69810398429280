import { userInfo$ } from '@gmini/common'
import {
  FilterAssignee,
  FilterDate,
  FilterPanel,
  FilterType,
  FilterUser,
} from '@gmini/components'
import { URLSearchParamsCustom } from '@gmini/utils'
import { useStore } from 'effector-react'
import { useEffect } from 'react'

import {
  CREATED_DATE_CODE_TL,
  CREATED_DATE_RANGE_TL,
  OWNER_IDS_TL,
  PROJECT_IS_NOT_SELECTED_ERROR,
  TEMPLATE_TYPE_IDS_TL,
  UPDATED_DATE_CODE_TL,
  UPDATED_DATE_RANGE_TL,
  INITIATORS_IDS_TL,
} from '../../../constants'
import { assigneeGroupList$ } from '../../assigneeGroupList'
import { templateTypes$, fetchTypesPending$ } from '../../templateTypes.store'
import {
  userList$,
  fetchUserListPending$,
  fetchAllUserListPending$,
} from '../../user.store'

import {
  allowedFilters$,
  fetchAllowedFilters,
  fetchAllowedFiltersPending$,
} from './model'

type TemplateListFilterPanelProps = {
  onChange: (query: URLSearchParamsCustom) => void
  projectUrn?: string | null
}

export const TemplateListFilterPanel = ({
  onChange,
  projectUrn,
}: TemplateListFilterPanelProps) => {
  const allowedFilters = useStore(allowedFilters$)
  const templateTypes = useStore(templateTypes$)
  const userList = useStore(userList$)
  const fetchUserListPending = useStore(fetchUserListPending$)
  const fetchAllUserListPending = useStore(fetchAllUserListPending$)
  const fetchAllowFiltersPending = useStore(fetchAllowedFiltersPending$)
  const fetchTemplateTypesPending = useStore(fetchTypesPending$)
  const userInfo = useStore(userInfo$)
  const assigneeGroupList = useStore(assigneeGroupList$)

  useEffect(() => {
    if (!projectUrn) {
      return
    }

    fetchAllowedFilters({
      projectUrn,
    })
  }, [projectUrn])

  const onChangeHandler = (query: URLSearchParamsCustom) => {
    onChange?.(query)

    if (!projectUrn) {
      throw new Error(PROJECT_IS_NOT_SELECTED_ERROR)
    }

    fetchAllowedFilters({
      projectUrn,
    }) // Чтобы хэндлить изменения замечаний другими пользователями
  }

  return (
    <FilterPanel
      items={[
        {
          title: 'Дисциплины',
          component: (
            <FilterType
              allowedItems={allowedFilters?.templateTypeIds || []}
              typeList={templateTypes}
              urlKey={TEMPLATE_TYPE_IDS_TL}
              loading={fetchTemplateTypesPending}
              disabled={!allowedFilters && fetchAllowFiltersPending}
              countSkeletonItems={10}
              onChange={onChangeHandler}
            />
          ),
        },
        {
          title: 'Дата изменения',
          component: (
            <FilterDate
              allowedRange={allowedFilters?.updatedDateRange || null}
              urlKeyCode={UPDATED_DATE_CODE_TL}
              urlKeyValue={UPDATED_DATE_RANGE_TL}
              disabled={!allowedFilters && fetchAllowFiltersPending}
              onChange={onChangeHandler}
            />
          ),
        },
        {
          title: 'Дата создания',
          component: (
            <FilterDate
              allowedRange={allowedFilters?.createdDateRange || null}
              urlKeyCode={CREATED_DATE_CODE_TL}
              urlKeyValue={CREATED_DATE_RANGE_TL}
              onChange={onChangeHandler}
              disabled={!allowedFilters && fetchAllowFiltersPending}
            />
          ),
        },
        {
          title: 'Автор',
          component: (
            <FilterUser
              userList={userList}
              urlKey={OWNER_IDS_TL}
              allowedItems={allowedFilters?.ownerIds || []}
              placeholder='Выбрать автора'
              disabled={
                fetchUserListPending ||
                fetchAllUserListPending ||
                (!allowedFilters && fetchAllowFiltersPending)
              }
              userInfo={userInfo}
              labelFirstEl='Я автор'
              onChange={onChangeHandler}
            />
          ),
        },
        {
          title: 'Инициатор чек-листа',
          component: (
            <FilterAssignee
              assigneeGroupList={assigneeGroupList.map(group => ({
                ...group,
                options: group.options.map(option => ({
                  ...option,
                  type: option.source,
                })),
              }))}
              urlKeyRole={INITIATORS_IDS_TL}
              urlKeyUser={INITIATORS_IDS_TL}
              allowedItems={allowedFilters?.initiatorIds || []}
              placeholder='Выбрать инициато...'
              disabled={
                fetchUserListPending ||
                fetchAllUserListPending ||
                (!allowedFilters && fetchAllowFiltersPending)
              }
              userInfo={userInfo}
              labelFirstEl='Назначено на меня'
              onChange={onChangeHandler}
            />
          ),
        },
      ]}
    />
  )
}
