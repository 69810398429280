import * as React from 'react'
import { ThemeProvider, CssBaseline } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { root } from 'effector-root'
import { createInspector } from 'effector-logger/inspector'
import { attachLogger } from 'effector-logger/attach'
import MomentUtils from '@date-io/moment'

import { ReactKeycloakProvider } from '@react-keycloak/web'
import { AuthClientTokens } from '@react-keycloak/core/lib/types'

import {
  DefaultSnackbarProvider,
  muiTheme,
  OfflineModal,
  PageTitleOptions,
} from '@gmini/ui-kit'
import { DndProvider } from 'react-dnd'
import MultiBackend from 'react-dnd-multi-backend'
import { Page404 } from '@gmini/ui-kit/lib/Page404'

import { MetrikaProvider } from '@gmini/common/lib/metrika/provider'

import { AuthContent } from '@gmini/common/lib/keycloakAuth/AuthContent'
import { saveAuthData } from '@gmini/common/lib/keycloakAuth/auth'

import { ApiCallService } from '@gmini/api-call-service'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import * as api from '@gmini/chm-api-sdk'

import { CustomHTML5toTouch } from '../dnd'

import * as config from '../config'
import {
  EditTemplatePage,
  ChecklistManagerPage,
  CreateTemplatePage,
  EditInstancePage,
  PreviewChecklistPage,
  CreateChecklistPopupPage,
} from '../components/pages'
import { initAmplitude } from '../config/amplitude'

import '../services/checklistsService'
import { keycloakClient } from '../config/keycloakConfig'

if (config.logEffector) {
  attachLogger(root)
  createInspector()
}

initAmplitude()

export const App = (): JSX.Element => {
  const onTokens = React.useCallback(
    ({ idToken, refreshToken, token }: AuthClientTokens) => {
      if (!token || !refreshToken || !idToken) {
        // Если приходят пустые токены, то значит юзер разлогинился или залогинился под другим юзернеймом на другой вкладке
        // и кейклок сам сделает редирект на страницу авторизации что бы обновить куки
        return
      }

      saveAuthData({
        accessToken: token,
        refreshToken,
        idToken,
      })
    },
    [],
  )

  // redirect /checklist-manager/**** => /****
  if (window.location.pathname.indexOf('/checklist-manager') === 0) {
    window.location.replace(
      `${window.location.origin}${window.location.pathname.replace(
        '/checklist-manager',
        '',
      )}`,
    )
  }

  return (
    <ReactKeycloakProvider
      authClient={keycloakClient}
      onTokens={onTokens}
      initOptions={{
        onLoad: 'login-required',
        checkLoginIframe: false,
      }}
    >
      <MetrikaProvider>
        <BrowserRouter>
          <ThemeProvider theme={muiTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DefaultSnackbarProvider>
                <PageTitleOptions siteName='Менеджер чек-листов' />

                <CssBaseline />

                <Helmet>
                  <link
                    rel='stylesheet'
                    href='https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap'
                  />
                </Helmet>
                <DndProvider
                  backend={MultiBackend as any}
                  options={CustomHTML5toTouch}
                >
                  <OfflineModal />
                  <AuthContent appName='Менеджер чек-листов'>
                    <Switch>
                      <Route exact path='/' component={ChecklistManagerPage} />
                      <Route
                        exact
                        path='/template/create'
                        component={CreateTemplatePage}
                      />
                      <Route
                        exact
                        path='/template/edit/:id'
                        component={EditTemplatePage}
                      />
                      <Route
                        exact
                        path='/instance/edit/:id'
                        component={EditInstancePage}
                      />
                      <Route
                        exact
                        path='/instance/preview/:templateId'
                        component={PreviewChecklistPage}
                      />
                      <Route
                        exact
                        path='/instance/create'
                        component={CreateChecklistPopupPage}
                      />
                      <Route component={Page404} />
                    </Switch>
                  </AuthContent>
                </DndProvider>
              </DefaultSnackbarProvider>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </BrowserRouter>
      </MetrikaProvider>
    </ReactKeycloakProvider>
  )
}

App.displayName = 'App'

ApiCallService.fail.watch(({ name, params, error }) => {
  if (error.status === 'fail' || (error as any).name === 'AbortError') {
    return
  }

  api.logApiError(error)
})
