import {
  passesFilterDate,
  passesFilterStatus,
  passesFilterType,
  passesFilterUser,
} from '@gmini/components'
import { filterDateEnrichment } from '@gmini/helpers'
import * as api from '@gmini/chm-api-sdk'

import { InstanceListFilters } from './InstanceList.types'

export const matchInstanceToFilters = (
  instance: api.Instance.InstancePopulated,
  filters: InstanceListFilters,
) => {
  const isPassOwnerFilter = passesFilterUser(filters.ownerIds, instance.ownerId)

  const isPassTypeFilter = passesFilterType(
    filters.templateTypeIds,
    instance.templateTypeId,
  )

  const isPassStatusFilter = passesFilterStatus(
    filters.statuses,
    instance.status,
  )

  const currentDate = new Date()

  const {
    enrichedUpdatedDateRange,
    enrichedCreatedDateRange,
  } = filterDateEnrichment(currentDate, filters)

  const isPassUpdatedDateFilter = passesFilterDate(
    enrichedUpdatedDateRange,
    instance.updatedAt,
  )

  const isPassCreatedDateFilter = passesFilterDate(
    enrichedCreatedDateRange,
    instance.createdAt,
  )

  const isPassSearchFilter = instance.name
    .toLowerCase()
    .includes(filters.filter.toLowerCase())

  return (
    isPassOwnerFilter &&
    isPassTypeFilter &&
    isPassStatusFilter &&
    isPassUpdatedDateFilter &&
    isPassCreatedDateFilter &&
    isPassSearchFilter
  )
}
