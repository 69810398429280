import { createAssigneeGroupListService } from '@gmini/helpers'

import { roleList$ } from './role.store'
import { allUserList$, userList$ } from './user.store'

export const {
  assigneeGroupList$,
  assigneeRoleList$,
  assigneeUserList$,
  mapRoleToAssigneeOption,
  mapUserToAssigneeOption,
  assigneeCompanyList$,
  mapCompanyToAssigneeOption,
} = createAssigneeGroupListService({ roleList$, userList$ })

export const {
  assigneeUserList$: assigneeAllUserList$,
} = createAssigneeGroupListService({
  roleList$,
  userList$: allUserList$,
})
