import {
  combine,
  createEffect,
  createEvent,
  createStore,
  merge,
} from 'effector'

import * as api from '@gmini/chm-api-sdk'

import { DateFilterItemsCode } from '@gmini/components'
import { filterDateEnrichment } from '@gmini/helpers'

import { clone } from 'ramda'

import { fetchAllowedFilters } from '../InstanceListFilterPanel/model'
import { ZERO_SEARCH } from '../../../constants'

export type Instance = api.Instance.Instance

export const createInstance = api.Instance.create.createContext()

export const removeInstance = api.Instance.remove.createContext()
export const removeInstanceStore = createEvent<{
  id: number
}>()

merge([
  removeInstance.doneData,
  createInstance.doneData,
]).watch(({ projectUrn }) => fetchAllowedFilters({ projectUrn }))

removeInstance.doneData.watch(({ id }) => {
  removeInstanceStore({ id })
})

export const fetchInstanceList = api.Instance.fetchList.createContext()
export const resetInstanceList = createEvent()

export type FetchInstanceListWithPrepareDateRange = api.Instance.FetchListParams & {
  updatedDateCode?: DateFilterItemsCode | null
  createdDateCode?: DateFilterItemsCode | null
}

export const fetchInstanceListWithEnrichDateFilter = createEffect<
  FetchInstanceListWithPrepareDateRange,
  unknown
>()

fetchInstanceListWithEnrichDateFilter.use(
  ({
    updatedDateRange,
    createdDateRange,
    updatedDateCode,
    createdDateCode,
    ...otherProps
  }) => {
    const currentDate = new Date()

    const {
      enrichedUpdatedDateRange,
      enrichedCreatedDateRange,
    } = filterDateEnrichment(currentDate, {
      updatedDateRange,
      createdDateRange,
      updatedDateCode,
      createdDateCode,
    })

    fetchInstanceList({
      ...otherProps,
      updatedDateRange: enrichedUpdatedDateRange,
      createdDateRange: enrichedCreatedDateRange,
    })
  },
)

type ById = {
  [id: string]: Instance
}

const byId$ = createStore<ById>({})
  .on(fetchInstanceList.doneData, (state, result) => {
    const next = { ...state }

    result.list.forEach(ch => (next[ch.id] = ch))
    return next
  })
  .on(createInstance.doneData, (state, result) => ({
    ...state,
    [result.id]: result,
  }))
  .on(removeInstanceStore, (state, { id }) => {
    const next = { ...state }
    delete next[id]
    return next
  })

export const addInstanceToIds = createEvent<{ search: string; id: number }>()

type IdsBySearchValue = Record<string | symbol, number[] | undefined>

const ids$ = createStore<IdsBySearchValue>({})
  .on(fetchInstanceList.done, (state, { params, result }) => {
    const next = { ...state }
    const search = params.filter || ZERO_SEARCH

    const prevIds = state[search] || []
    const resultIds = result.list.map(({ id }) => id)
    const nextIds = prevIds
      .filter(prevId => resultIds.every(id => id !== prevId))
      .concat(...resultIds)

    next[search] = nextIds
    return next
  })
  .on(resetInstanceList, state => ({}))
  .on(removeInstanceStore, (state, { id }) => {
    const next = { ...state }

    Object.entries(state).forEach(([key, ids]) => {
      const nextIds = ids?.filter(item => item !== id) || []
      if (ids?.length !== nextIds.length) {
        next[key] = ids
      }
    })

    return next
  })
  .on(addInstanceToIds, (state, result) => {
    const next = clone(state)

    const search = result.search || ZERO_SEARCH

    next[search] = [...new Set([result.id, ...(next[search] || [])])]

    return next
  })

export const totalInstances$ = createStore<number | null>(null).on(
  fetchInstanceList.done,
  (state, { result }) => result.total,
)

export const instanceList$ = combine({ byId$, ids$, totalInstances$ })
