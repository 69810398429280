import { useStore } from 'effector-react'

import { ReactNode, useEffect } from 'react'

import { WithHeader } from '@gmini/components'

import { useNavbar, getModulesLinkFromEnv } from '@gmini/common'

import { useQuery } from '@gmini/utils'

import { Tabs } from '@gmini/ui-kit'

import { editorPending$ } from '../../editorPending'

import { fetchTypes } from '../../templateTypes.store'

import { envLinks } from '../../../config'
import { fetchStatusList } from '../../instanceStatus'
import { PROJECT_URN } from '../../../constants'
import { fetchAllUserList, fetchUserList } from '../../user.store'
import { fetchRoleList } from '../../role.store'

type ChecklistManagerWrapperProps = {
  children: ReactNode | ReactNode[]
  loading?: boolean
  brandSecondary?: ReactNode
}

export const ChecklistManagerWrapper = ({
  children,
  loading,
  brandSecondary,
}: ChecklistManagerWrapperProps) => {
  const editorPending = useStore(editorPending$)
  const query = useQuery()
  const projectUrn = query.get(PROJECT_URN) || null

  useEffect(() => {
    fetchTypes.submit()
    fetchStatusList.submit()
    fetchAllUserList.submit({})
    fetchRoleList.submit()
  }, [])

  useEffect(() => {
    if (projectUrn) {
      fetchUserList.submit({ projectUrn })
    }
  }, [projectUrn])

  const { NavbarDropDown, opened } = useNavbar({
    navModules: getModulesLinkFromEnv(envLinks, projectUrn),
  })

  const onChangeTab = (tabIndex: number) => {
    try {
      const links = JSON.parse(envLinks)
      if (tabIndex === 0 || !links.ism) {
        return
      }

      window.location.href = `https://${links.ism}${
        projectUrn ? `?projectUrn=${projectUrn}` : ''
      }`
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <WithHeader
      NavbarDropDown={NavbarDropDown}
      openedNavbarDropDown={opened}
      loading={editorPending || loading}
      brandSecondary={brandSecondary}
      Tabs={
        <Tabs
          onChangeTab={onChangeTab}
          activeTabIndex={0}
          headerStyles={{
            fontSize: '14px',
            height: '49px',
            gap: 19,
            marginTop: 'auto',
            marginBottom: '-1px',
            borderBottom: 'none',
          }}
          contentStyles={{
            height: '100%',
            paddingTop: '0',
            display: 'flex',
            minHeight: 0,
          }}
          tabs={[
            {
              title: 'Чек-листы',
              content: null,
            },
            {
              title: 'Замечания',
              content: null,
            },
          ]}
        />
      }
      title='Чек-листы'
    >
      {children}
    </WithHeader>
  )
}
