import { ChangeEvent, useEffect, useRef, useState } from 'react'

import { useStore, useStoreMap } from 'effector-react'

import {
  LongText,
  Negative,
  Neutral,
  Positive,
  PlusCircle,
  ArrowDown,
  IconButton,
  HorizontalThreeDots,
  Trash,
  Download,
  Tooltip,
} from '@gmini/ui-kit'

import { ClickAwayListener } from '@material-ui/core'

import { useParams } from 'react-router'

import moment from 'moment'

import {
  useFilesValidation,
  fileValidationRules,
  isImage,
} from '@gmini/helpers'

import { DropdownMenu, FileValidationErrorsPopup } from '@gmini/components'

import { FileViewer } from '@gmini/common'

import * as api from '@gmini/chm-api-sdk'

import { RadioButton } from '../../atoms'

import { DEFAULT_DISPLAY_DATE_FORMAT } from '../../../constants'

import { editorPending$ } from '../../editorPending'

import {
  uploadSectionItemFiles,
  fetchSectionItemFileList,
  deleteSectionItemFile,
  downloadSectionItemFile,
  filesByTemplateSectionId$,
  fileLinkById$,
} from '../../file.store'

import {
  Container,
  NameWrapper,
  IndexNumber,
  ActionList,
  Button,
  Body,
  Name,
  Title,
  AnswerBlock,
  Description,
  TitleText,
  ButtonWrapper,
  ActionListItem,
  HiddenInput,
  BodyItem,
  FileWrapper,
  DocumentTitle,
  FileUploadAt,
  Document,
} from './Question.styled'

enum AnswerType {
  YES = 'YES',
  NO = 'NO',
  NO_ANSWER = 'NO_ANSWER',
}

type QuestionProps = {
  question: api.Template.Question
  index: string
  onUpdateAnswer: (props: api.Instance.InstanceItemProperty) => void
  itemProperty?: api.Instance.InstanceItemProperty
  instanceStatus: api.InstanceStatus.Enum
  instanceVersion?: number
  isPreview?: boolean
}

enum Action {
  AttachFile = 'AttachFile',
}

const actions = [
  { name: 'Документ / Фото', Icon: Document, action: Action.AttachFile },
]

export const Question = ({
  question,
  index,
  onUpdateAnswer,
  itemProperty,
  instanceStatus,
  instanceVersion,
  isPreview = false,
}: QuestionProps) => {
  const pending = useStore(editorPending$)
  const params = useParams<{ id?: string }>()
  const instanceId = params.id ? Number(params.id) : null
  const [
    anchorDropdownMenu,
    setAnchorElDropdownMenu,
  ] = useState<HTMLButtonElement | null>(null)
  const [viewerImageCurrentIdx, setViewerImageCurrentIdx] = useState(0)
  const [openFilesViewer, setOpenFilesViewer] = useState(false)
  const [isOpenActionList, setIsOpenActionList] = useState(false)
  const [
    openedFileValidationErrorsPopup,
    setOpenedFileValidationErrorsPopup,
  ] = useState(false)
  const [selectedFileId, setSelectedFileId] = useState<number | null>(null)

  const fileList = useStoreMap({
    store: filesByTemplateSectionId$,
    keys: [question.id],
    fn: (filesByTemplateSectionId, [templateSectionId]) =>
      templateSectionId && templateSectionId in filesByTemplateSectionId
        ? filesByTemplateSectionId[templateSectionId]
        : null,
  })

  const fileLinkById = useStore(fileLinkById$)

  const inputRef = useRef<HTMLInputElement>(null)

  const imgList = fileList?.filter(isImage)
  const imgLinkList = imgList?.map(({ id }) => fileLinkById[id]).filter(Boolean)

  useEffect(() => {
    if (!instanceId || !instanceVersion) {
      return
    }

    fetchSectionItemFileList({
      instanceId,
      instanceVersion,
      templateSectionItemId: question.id,
    })
  }, [instanceId, instanceVersion, question.id])

  const handleChooseAnswer = (answer: AnswerType) => {
    if (
      instanceStatus !== api.InstanceStatus.Enum.FINISHED &&
      answer !== itemProperty?.answer
    ) {
      onUpdateAnswer({ answer, templateSectionItemId: question.id })
    }
  }

  const {
    fileErrorMessages,
    validateFiles,
    clearFileErrors,
  } = useFilesValidation({
    rules: fileValidationRules,
    onError: () => setOpenedFileValidationErrorsPopup(true),
  })

  const onUploadFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target

    if (!files || !instanceId || !instanceVersion) {
      return
    }

    clearFileErrors()

    const allowFiles = validateFiles(Object.values(files), fileList?.length)

    if (allowFiles.length) {
      await uploadSectionItemFiles(
        { instanceId, instanceVersion, templateSectionItemId: question.id },
        allowFiles,
      )
    }

    // eslint-disable-next-line require-atomic-updates
    event.currentTarget.value = ''
  }

  const handleCloseDropdownMenu = () => {
    setAnchorElDropdownMenu(null)
    setSelectedFileId(null)
  }

  const onClickUploadButton = () => {
    inputRef.current?.click()
  }

  const onActionClick = (action: Action) => {
    switch (action) {
      case Action.AttachFile: {
        return onClickUploadButton()
      }
    }
  }

  const onDeleteFile = () => {
    handleCloseDropdownMenu()
    if (!selectedFileId || !instanceId || !instanceVersion) {
      return
    }
    deleteSectionItemFile({
      fileId: selectedFileId,
      instanceId,
      instanceVersion,
    })
  }

  const onDownloadFile = () => {
    handleCloseDropdownMenu()
    if (selectedFileId) {
      downloadSectionItemFile({ fileId: selectedFileId })
    }
  }

  return (
    <Container>
      <FileValidationErrorsPopup
        open={openedFileValidationErrorsPopup}
        onClose={() => setOpenedFileValidationErrorsPopup(false)}
        errors={fileErrorMessages}
      />
      <HiddenInput
        ref={inputRef}
        multiple={true}
        id='file'
        type='file'
        name='file'
        onChange={onUploadFile}
      />
      <NameWrapper>
        <IndexNumber>
          <LongText withoutRightText partSize={10}>
            {index}
          </LongText>
        </IndexNumber>
        <TitleText>
          <Name>
            <Title>{question.name}</Title>
          </Name>
          <Description>{question.description}</Description>
        </TitleText>
      </NameWrapper>

      <Body>
        <BodyItem>
          <AnswerBlock>
            <RadioButton
              active={itemProperty?.answer === AnswerType.YES}
              onClick={() => handleChooseAnswer(AnswerType.YES)}
              icon={<Positive />}
              label='Да'
              disabled={pending}
            />
            <RadioButton
              active={itemProperty?.answer === AnswerType.NO}
              onClick={() => handleChooseAnswer(AnswerType.NO)}
              icon={<Negative />}
              label='Нет'
              disabled={pending}
            />
            <RadioButton
              active={itemProperty?.answer === AnswerType.NO_ANSWER}
              onClick={() => handleChooseAnswer(AnswerType.NO_ANSWER)}
              icon={<Neutral />}
              label='Нет ответа'
              disabled={pending}
            />
            <ClickAwayListener onClickAway={() => setIsOpenActionList(false)}>
              <ButtonWrapper>
                <Tooltip
                  title={
                    instanceStatus !== api.InstanceStatus.Enum.FINISHED
                      ? ''
                      : 'Чек-лист завершен'
                  }
                  noMaxWidth
                >
                  <Button
                    leftIcon={<PlusCircle />}
                    color='secondary'
                    onClick={() => setIsOpenActionList(true)}
                    rightIcon={<ArrowDown />}
                    pressed={isOpenActionList}
                    size='regular'
                    disabled={
                      pending ||
                      instanceStatus === api.InstanceStatus.Enum.FINISHED ||
                      isPreview ||
                      Number(fileList?.length) >= 20
                    }
                  >
                    Добавить
                  </Button>
                </Tooltip>

                {isOpenActionList ? (
                  <ActionList>
                    {actions.map(({ name, Icon, action }, idx) => (
                      <ActionListItem
                        key={action}
                        onClick={() => onActionClick(action)}
                      >
                        <Icon color='#353B60' />
                        <span>{name}</span>
                      </ActionListItem>
                    ))}
                  </ActionList>
                ) : null}
              </ButtonWrapper>
            </ClickAwayListener>
          </AnswerBlock>
        </BodyItem>
        {imgLinkList ? (
          <FileViewer
            linkList={imgLinkList}
            open={openFilesViewer}
            setOpen={setOpenFilesViewer}
            startIndex={viewerImageCurrentIdx}
          />
        ) : null}

        {fileList
          ?.sort((a, b) => a.name.localeCompare(b.name))
          .map(file => (
            <BodyItem key={file.id}>
              <FileWrapper
                onClick={() => {
                  if (!isImage(file)) {
                    return
                  }
                  const imgIdx = imgList?.indexOf(file) || 0
                  setViewerImageCurrentIdx(imgIdx)
                  setOpenFilesViewer(true)
                }}
                clickable={isImage(file)}
              >
                <Document color='#353B60' width='24px' height='24px' />
                <DocumentTitle>
                  <LongText partSize={20} withoutRightText>
                    {file.name}
                  </LongText>
                </DocumentTitle>
                <FileUploadAt>
                  {moment(file.uploadAt).format(DEFAULT_DISPLAY_DATE_FORMAT)}
                </FileUploadAt>
                <IconButton
                  onClick={event => {
                    event.stopPropagation()
                    setSelectedFileId(file.id)
                    setAnchorElDropdownMenu(event.currentTarget)
                  }}
                  size='regular'
                  active={selectedFileId === file.id}
                  disabled={
                    pending ||
                    instanceStatus === api.InstanceStatus.Enum.FINISHED
                  }
                >
                  <HorizontalThreeDots />
                </IconButton>
              </FileWrapper>
            </BodyItem>
          ))}
      </Body>
      <DropdownMenu
        anchorEl={anchorDropdownMenu}
        handleClose={handleCloseDropdownMenu}
        items={[
          {
            name: 'Скачать',
            onClick: onDownloadFile,
            icon: <Download color='#353B60' />,
          },
          {
            name: 'Удалить',
            onClick: onDeleteFile,
            icon: <Trash color='#353B60' />,
          },
        ]}
      />
    </Container>
  )
}
