import { FetchService } from '@gmini/utils'

import { removeAuthHeader, setAuthHeader } from '@gmini/common/lib/auth/auth'

import { tokenData$ } from '@gmini/common/lib/keycloakAuth'

import * as smApi from '@gmini/sm-api-sdk'

import { refreshTokenFunc } from '@gmini/common/lib/keycloakAuth/auth/authData'

import * as api from '@gmini/chm-api-sdk'

import { localBackUrl } from '../config'

export const checklistManagerFetch = new FetchService({
  baseUrl: localBackUrl ? localBackUrl : '/api/checklists',
  refreshTokenFunc,
})

const gStationModelNavigation = new FetchService({
  baseUrl: '/api/g-station-model-navigation',
  refreshTokenFunc,
})

export const authApi = new FetchService({ baseUrl: '/api/auth/v1' })

tokenData$.watch(tokenData => {
  if (tokenData?.accessToken) {
    setAuthHeader(
      [authApi, checklistManagerFetch, gStationModelNavigation],
      tokenData.accessToken,
    )
  } else {
    removeAuthHeader([authApi, checklistManagerFetch, gStationModelNavigation])
  }
})

smApi.authConnect(authApi)
api.checklistManagerConnect(checklistManagerFetch)
smApi.navigationModelRepoConnect(gStationModelNavigation)
